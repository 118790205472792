import { useStoreContext, useUI } from 'contexts';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import COOKIE_ENUMS from 'lib/utils/cookieEnums';
import useCustomer from './customer/useCustomer';

export const hasSuggestedSessionKey = 'suggestedStoreLocale';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
const DELAY_TIME = 200;

const getSuggestion = async (currentStore, currentLocale) => {
  let store = Cookies.get('country_store');
  let locale = Cookies.get('country_locale');

  if (!store || !locale) {
    await delay(DELAY_TIME);
    store = Cookies.get('country_store');
    locale = Cookies.get('country_locale');
  }

  const suggestion = { store, locale };
  const validSuggestion = store && locale && (store !== currentStore || locale !== currentLocale);

  return validSuggestion ? suggestion : null;
};

const useSuggestStoreLocale = () => {
  const { locale, store } = useStoreContext();
  // TODO: add customer loading (!isLoggedIn && !customerLoading)
  const { customer: { isLoggedIn } = { isLoggedIn: false } } = useCustomer();
  const { openStoreLocaleSelector, initializeStoreLocaleSelector } = useUI();

  useEffect(() => {
    const suggestLocaleToUser = async () => {
      let hasSuggested = Cookies.get(COOKIE_ENUMS.SELECTED_STORE_LOCALE);

      if (hasSuggested === undefined) {
        await delay(DELAY_TIME);
        hasSuggested = Cookies.get(COOKIE_ENUMS.SELECTED_STORE_LOCALE);
      }

      if (!hasSuggested && !isLoggedIn) {
        const suggestedStoreLocale = await getSuggestion(store, locale);
        if (suggestedStoreLocale) {
          openStoreLocaleSelector(suggestedStoreLocale);
        } else {
          initializeStoreLocaleSelector();
        }
      } else {
        initializeStoreLocaleSelector();
      }
    };

    /* 
      check the cookie for locale and store set in middleware.ts. Geo data from req.geo from vercel.
      if user has not previously selected a store/locale, suggest the one from the cookie. 
    */
    suggestLocaleToUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, store, isLoggedIn]);
};

export default useSuggestStoreLocale;
