import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useCart, useCustomer, useUI, useUpdateCheckoutAttributes } from 'hooks';
import { Icon, SiteLink, Swiper } from 'components/shared';
import { formatPrice, getLinkHref } from 'lib/utils/helpers';
import { LINK_QUERY_FIELDS } from 'lib/contentful/fragments';
import { useStoreContext } from 'contexts';
import { trackInWindow, trackEvents } from 'hooks/tracking/track';
import Product from 'components/shared/product';
import { useRouter } from 'next/router';
import { getConsentCookie } from 'lib/utils/getConsentCookie';
import springConfigs from 'lib/utils/springConfigs';
import { Spinner } from 'components/shared/icons/ui';
import { getResource, getResourceText } from 'lib/resources';
import toItem from 'hooks/tracking/to-item';
import Button from 'components/shared/button';
import LineItem from './lineItem';
import { HEADER_HEIGHT, HEADER_HEIGHT_DESKTOP } from '../../../constants';

const paymentIconsPerLocale = {
  eu: ['paypal', 'googlepay', 'ideal', 'visa', 'master'],
  us: ['paypal', 'googlepay', 'visa', 'master'],
  uk: ['paypal', 'googlepay', 'visa', 'master'],
};

export const getCartQuery = ({ locale = 'en' }) => `
  {
    globalModuleCollection(
      locale: "${locale}"
      limit: 1
      where: { name_contains: "Cart" }
    ) {
      items {
        sys {
          id
        }
        resourcesCollection {
          items {
            ... on ComponentMicrocopy {
              __typename
              name
              text
              tag
            }
            ... on ComponentLink {
              name
              ${LINK_QUERY_FIELDS}
            }
          }
        }
      }
    }
  }
`;

const Cart = ({ data }) => {
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const [cartGifts, setCartGifts] = useState([]);
  const [filteredCartGifts, setFilteredCartGifts] = useState([]);

  const { cart, isEmpty, isLoading, checkout, getCartGifts } = useCart();
  const { customer } = useCustomer();
  const { currency } = useStoreContext();
  const { events } = useRouter();
  const { store } = useStoreContext();

  const { displayCart, closeCart } = useUI();

  const { lineItems: lineItems_ = [] } = cart || {};

  useEffect(() => {
    const fetchGiftCards = async () => {
      const response = await getCartGifts(lineItems_);
      if (!response) return;
      setFilteredCartGifts(response.filteredNewGiftLineItems);
      setCartGifts(response.newGiftLineItems);
    };
    fetchGiftCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayCart, JSON.stringify(checkout)]);

  const paymentIcons = paymentIconsPerLocale[store]?.map(icon => (
    <Icon className="h-8 w-8 lg:h-[1.875rem] lg:w-[3.125rem]" type={icon} />
  ));

  const lineItems = lineItems_.map(lineItem => {
    if (cartGifts.map(cartGift => cartGift.merchandise.id).includes(lineItem.merchandise.id)) {
      const { attributes } = cartGifts.find(
        cartGift => cartGift.merchandise.id === lineItem.merchandise.id
      );
      return {
        ...lineItem,
        attributes,
      };
    }
    return lineItem;
  });

  useEffect(() => {
    if (cart && displayCart)
      trackInWindow({
        event: trackEvents.viewCart,
        page_title: document.title,
        page_path: window.location.pathname,
        currency,
        value: parseFloat(cart?.cost?.subtotalAmount?.amount),
        items: lineItems.map(({ merchandise, merchandise: { product }, quantity }) =>
          toItem(product, merchandise, {
            quantity,
          })
        ),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayCart]);

  useEffect(() => {
    events.on('routeChangeComplete', closeCart);
    return () => events.off('routeChangeComplete', closeCart);
  }, [closeCart, events]);

  const cartStyle = useSpring({
    transform: displayCart ? 'translateX(0%)' : 'translateX(100%)',
    config: springConfigs.accordionWrapper,
    delay: displayCart ? 50 : 0,
  });

  const blackoutStyle = useSpring({
    opacity: displayCart ? 1 : 0,
    config: springConfigs.fastClamped,
    delay: displayCart ? 0 : 50,
  });

  const recommendedProductsKey = lineItems?.map(({ variant }) => variant?.product?.id);
  const recommendedProducts = [];
  // const { data: recommendedProducts } = useSWR(
  //   recommendedProductsKey,
  //   async (...ids) => getProductRecommendations(currency)(ids, 8),
  //   { fallbackData: [], revalidateOnFocus: false }
  // );

  const resources = data?.globalModuleCollection?.items[0]?.resourcesCollection?.items;
  const t = getResourceText(resources);

  const emptyCartLink = getResource(resources)('Empty cart link');
  const bulletPoints = resources.filter(({ tag }) => tag === 'bullet').map(({ text }) => text);

  const getMultipassUrl = async (email, webUrl) => {
    try {
      const response = await fetch('/api/shopify/account/multipass', {
        method: 'POST',
        headers: { currency },
        body: JSON.stringify({
          email,
          webUrl,
        }),
      });
      const multipassData = await response.json();
      if (response.ok) {
        return multipassData.url;
      }
      // eslint-disable-next-line no-console
      console.error('useMultipass failed: Check Shopify multipass secret and input');
      // if multipass doesn't work, we send oiginal webUrl
      return webUrl;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('useMultipass failed: ', error?.data?.error);
      return null;
    }
  };

  const updateCheckoutAttributes = useUpdateCheckoutAttributes();

  const handleCheckout = async () => {
    setIsLoadingCheckout(true);

    const checkoutObject = {
      currency,
      order_net_total: cart?.cost?.subtotalAmount?.amount,
      tax: cart?.cost?.totalTaxAmount?.amount || '0.0',
      order_total: cart?.cost?.totalAmount?.amount,
      coupon_name: cart?.appliedGiftCards?.[0]?.lastCharacters ?? '',
      coupon_amount: cart?.appliedGiftCards?.[0]?.amountUsedV2?.amount ?? '',
      total_discount: lineItems.reduce((acc, lineItem) => {
        const discount = lineItem.merchandise?.compareAtPriceV2?.amount
          ? parseFloat(lineItem.merchandise?.compareAtPriceV2?.amount) -
            parseFloat(lineItem.merchandise?.priceV2?.amount)
          : 0;
        return acc + discount * lineItem.quantity;
      }, 0),
      actionField: {
        step: 1,
      },
      items: lineItems.map(({ merchandise, merchandise: { product }, quantity }) =>
        toItem(product, merchandise, {
          quantity,
          value: merchandise.priceV2?.amount,
          dimension3: 'Ecommerce',
        })
      ),
    };

    trackInWindow({
      event: trackEvents.beginCheckout,
      ecommerce: {
        checkout: checkoutObject,
      },
    });

    const consentCookie = getConsentCookie();
    await updateCheckoutAttributes({ consentCookie, checkoutData: JSON.stringify(checkoutObject) });

    if (customer?.isLoggedIn) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const url = await getMultipassUrl(customer.email, cart.webUrl);
      document.location.href = url;
    } else {
      document.location.href = cart.webUrl;
    }
    setTimeout(() => {
      setIsLoadingCheckout(false);
    }, 2000);
  };

  // const discountLineItemsSubtotal = lineItems
  //   .filter(l => l?.attributes?.find(({ key }) => key === '_discount'))
  //   .reduce((acc, curr) => acc + curr?.merchandise?.priceV2?.amount * curr?.quantity, 0);

  return (
    <>
      <animated.button
        type="button"
        onClick={() => closeCart()}
        className={`fixed left-0 top-0 z-[10010] h-full w-full bg-blue bg-opacity-30 md:z-[10025] ${
          displayCart ? 'pointer-events-auto' : 'pointer-events-none'
        }`}
        style={blackoutStyle}
      />
      <animated.section
        style={cartStyle}
        className="fixed right-0 top-0 z-[10020] flex h-full w-full flex-col overflow-y-auto bg-white text-blue h-screen-ios md:z-[10026] md:w-[645px]"
      >
        <div
          style={{
            '--height': HEADER_HEIGHT,
            '--heightDesktop': HEADER_HEIGHT_DESKTOP,
          }}
          className="row flex h-[var(--height)] flex-shrink-0 items-center justify-between px-[16px] md:h-[var(--heightDesktop)] md:px-[30px]"
        >
          <h1 className="font-nimbus-sans-extd-d text-[20px] font-bold uppercase">{t('Cart')}</h1>
          <button type="button" onClick={() => closeCart()}>
            <span className="text-[12px] leading-[14px]">{t('Close', 'Close')}</span>
          </button>
        </div>
        <div className="h-[1px] flex-shrink-0 bg-blue md:mx-[30px]" />
        <div className="flex h-full min-h-[150px] flex-col justify-between overflow-y-auto">
          <div className={`shrink-0 px-[16px] md:px-[30px] ${isEmpty ? 'h-full' : ''}`}>
            {!isEmpty ? (
              lineItems.map(lineItem => (
                <LineItem
                  lineItem={lineItem}
                  key={lineItem.id}
                  removeCopy={t('Remove', 'Remove')}
                  colorCopy={t('Color', 'Color')}
                  sizeCopy={t('Size', 'Size')}
                />
              ))
            ) : (
              <div className="flex h-full flex-col items-center justify-center">
                <p className="font-nimbus-sans-extd-d text-[20px] font-bold uppercase leading-[0.9]">
                  {t('Your cart is empty', 'Your cart is empty')}
                </p>
                {emptyCartLink && (
                  <p className="mt-[6px] font-nimbus-sans-extd-d text-[12px] uppercase underline">
                    <SiteLink
                      href={getLinkHref(emptyCartLink.link)}
                      external={emptyCartLink.externalLink}
                    >
                      {emptyCartLink.text}
                    </SiteLink>
                  </p>
                )}
              </div>
            )}
          </div>
          {recommendedProducts.length > 0 && (
            <>
              <div className="pb-[6px]">
                <div className="mx-[16px] mb-[20px] h-[1px] flex-shrink-0 bg-blue md:mx-[30px]" />
                <p className="mb-[15px] px-[16px] text-center font-nimbus-sans-extd-d text-[14px] font-bold uppercase leading-[0.8] md:px-[30px] md:text-left md:text-[20px]">
                  {t('You may also like', 'You may also like')}
                </p>
                <div className="min-h-[160px] md:min-h-[240px] md:px-[30px]">
                  <Swiper
                    className="w-full"
                    slidesOffsetBefore={16}
                    slidesOffsetAfter={16}
                    spaceBetween={12}
                    slidesPerView={3.25}
                    breakpoints={{
                      768: {
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 0,
                      },
                    }}
                  >
                    {recommendedProducts.map(product => (
                      <Product key={product.id} product={product} t={t} minimal />
                    ))}
                  </Swiper>
                </div>
              </div>
            </>
          )}
        </div>
        {filteredCartGifts.length > 0 && (
          <div className="px-[16px] md:px-[30px]">
            <div className="border-t-[1px] border-blue">
              <p className="pt-2 font-nimbus-sans-extd-d text-[14px] font-bold uppercase md:pt-4 md:text-base md:leading-4">
                {t('Gift with purchase', 'A gift for you')}
              </p>
              {filteredCartGifts.map(cartGift => (
                <LineItem
                  key={cartGift.id}
                  lineItem={cartGift}
                  removeCopy={t('Remove', 'Remove')}
                  colorCopy={t('Color', 'Color')}
                  sizeCopy={t('Size', 'Size')}
                  isInCart={false}
                />
              ))}
            </div>
          </div>
        )}
        {!isEmpty && (
          <>
            <div className="flex flex-col  justify-end px-[16px] pb-[16px] md:px-[30px] md:pb-[35px]">
              <div className="hidden h-[0.0625rem] bg-blue lg:block" />
              {lineItems.length > 0 && (
                <div className="mt-[8px] flex justify-between">
                  <span className="text-[12px]">{t('Total', 'Total')}</span>
                  <span className="text-[12px] font-bold">
                    {formatPrice(
                      cart?.cost?.subtotalAmount?.amount,
                      cart?.cost?.subtotalAmount?.currencyCode,
                      2,
                      2
                    )}
                  </span>
                </div>
              )}
              <Button
                type="Button (Blue)"
                className="mb-4 mt-[16px]"
                onClick={handleCheckout}
                disabled={isLoadingCheckout || isLoading}
              >
                {isLoadingCheckout ? <Spinner className="h-[1.5em]" /> : t('Checkout', 'Checkout')}
              </Button>
              <div className="flex justify-center gap-1 lg:gap-2">{paymentIcons}</div>
            </div>
          </>
        )}
      </animated.section>
    </>
  );
};

export default Cart;
