import { useEffect, useMemo, useState } from 'react';
import { useSpring, animated, useTransition } from 'react-spring';
import { Image as SiteImage, SiteLink } from 'components/shared';
import { filterByType, filterByName, getLinkHref } from 'lib/utils/helpers';
import { useUI, useCart } from 'hooks';
import DenhamLogo from 'components/shared/icons/denham-logo';
import { Cart as CartIcon, Heart, Search as SearchIcon } from 'components/shared/icons/ui';
import { useStoreContext } from 'contexts';
import { gtmClickElementIds, trackEvents, trackInWindow } from 'hooks/tracking/track';
import springConfig from 'lib/utils/springConfigs';
import { useRouter } from 'next/router';
import useWishlist from 'hooks/swym/useWishlist';
import { localeLabels, storeLabels } from 'lib/storeLocales/storeLocales';
import { HEADER_HEIGHT_DESKTOP } from '../../../constants';
import { USP_BANNER_HEIGHT } from '../uspBanner';
import NavLinkLabelWrapper from './nav-link-label-wrapper';

const Desktop = ({ data, isScrolledDown, isScrollingDown, transparency }) => {
  const {
    toggleCart,
    openStoreLocaleSelector,
    displayStoreLocaleSelector,
    displayUspBanner,
    isDesktop,
  } = useUI();
  const { store, locale } = useStoreContext();
  const { count: cartCount } = useCart();
  const { pathname } = useRouter();
  const { count: wishlistCount } = useWishlist();

  const isWishlistPage = pathname === '/wishlist';

  const mainNav = data.filter(filterByName('MainNav'))[0];
  const accountMicrocopy = data.filter(filterByName('Account'))[0]?.text;

  const [submenuId, setSubmenuId] = useState(null);
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const [slideStyle, animateSlide] = useSpring(() => ({ height: 0 }));
  const [headerStyle, animateHeader] = useSpring(() => ({
    transform: 'translateY(0%)',
    config: springConfig.easeInButBetter,
  }));

  const [hover, setHover] = useState(false);

  const isTransparent =
    transparency &&
    ((!isScrolledDown && !hover) || (isScrollingDown && !displayStoreLocaleSelector));

  const clearSubmenu = banner => {
    setSubmenuOpen(false);
    if (banner?.sys?.id) {
      trackInWindow({
        event: trackEvents.componentBannersClick,
        component_id: banner.sys.id,
        component_name: banner.title,
        page_path: window.location.pathname,
      });
    }
  };

  const [previousSubmenuId, setPreviousSubMenuId] = useState(null);
  const changeSubMenuId = id =>
    setSubmenuId(previous => {
      if (previous !== id) setPreviousSubMenuId(previous);
      return id;
    });

  useEffect(() => {
    animateHeader({
      transform: 'translateY(0%)',
    });
  }, [animateHeader]);

  useEffect(() => {
    if (isScrolledDown && isScrollingDown && !displayStoreLocaleSelector) {
      setSubmenuOpen(false);

      animateHeader({
        transform: 'translateY(-100%)',
      });
    } else {
      animateHeader({
        transform: 'translateY(0%)',
      });
    }
  }, [isScrolledDown, isScrollingDown, animateHeader]);

  useEffect(() => {
    if (!isSubmenuOpen) {
      setSubmenuId(null);
      setPreviousSubMenuId(null);
    }
  }, [isSubmenuOpen]);

  useEffect(() => {
    animateSlide.start({
      height: isSubmenuOpen ? 360 : 0,
      delay: isSubmenuOpen ? 0 : 200,
      config: springConfig.easeInButBetter,
    });
  }, [animateSlide, isSubmenuOpen]);

  const renderNavL3 = item => {
    const { __typename: type } = item || {};

    if (type === 'ComponentLink') {
      return (
        <NavLinkLabelWrapper key={item.sys.id} item={item}>
          <SiteLink
            className="block font-nimbus-sans-extd-d font-bold uppercase hover:underline"
            href={getLinkHref(item.link)}
            external={item.externalLink}
            onClick={clearSubmenu}
            id={`${gtmClickElementIds.header.headerDropdownParentLink} ~ ${item.name}`}
          >
            {item.text}
          </SiteLink>
        </NavLinkLabelWrapper>
      );
    }

    if (type === 'ComponentNav') {
      const links = item?.linksCollection?.items?.filter(filterByType('ComponentLink')) || [];
      const title = item?.link ? (
        <SiteLink
          key={item.sys.id}
          className="hover:underline"
          href={getLinkHref(item.link?.link)}
          external={item.link?.externalLink}
          onClick={clearSubmenu}
          id={`${gtmClickElementIds.header.headerDropdownParentLink} ~ ${item.name}`}
        >
          {item.title}
        </SiteLink>
      ) : (
        item.title
      );

      return (
        <ul key={item.sys.id}>
          <li className="mb-[10px] font-nimbus-sans-extd-d font-bold uppercase">
            <NavLinkLabelWrapper item={item}>{title}</NavLinkLabelWrapper>
          </li>
          {links
            .map(l => ({
              ...l,
              i: l,
            }))
            .map(({ sys, link, externalLink, text, i, name }) => (
              <li key={sys.id} className="text-[12px] capitalize leading-[20px]">
                <NavLinkLabelWrapper item={i}>
                  <SiteLink
                    className="mt-[2px] hover:underline"
                    href={getLinkHref(link)}
                    external={externalLink}
                    onClick={clearSubmenu}
                    id={`${gtmClickElementIds.header.headerDropdownChildLink} ~ ${name}`}
                  >
                    {text}
                  </SiteLink>
                </NavLinkLabelWrapper>
              </li>
            ))}
        </ul>
      );
    }

    return null;
  };

  const renderNavL2 = nav => {
    const banners = nav?.bannersCollection?.items || [];
    const hasBanners = banners.length > 0;
    const navL3Items = nav?.linksCollection?.items || [];

    return (
      <>
        <div className="flex gap-[32px] p-[16px] pr-[32px] text-blue lg:gap-[64px] lg:pr-[64px] xl:gap-[120px] xl:pr-[120px]">
          {navL3Items.map(renderNavL3)}
        </div>
        {hasBanners && (
          <div className="flex h-[325px] max-w-[max(60%,750px)] flex-1 gap-[16px] justify-self-center">
            {banners.filter(Boolean).map(banner => (
              <SiteLink
                key={banner.sys.id}
                href={getLinkHref(banner.link?.link)}
                external={banner.link?.externalLink}
                onClick={() => clearSubmenu(banner)}
                className="relative flex-1"
                id={`${gtmClickElementIds.header.headerDropdownBanner} ~ ${banner?.link?.name}`}
              >
                <SiteImage
                  src={
                    isDesktop
                      ? banner.image || banner.mobileImage
                      : banner.mobileImage || banner.image
                  }
                  priority
                  loading="eager"
                  className="pointer-events-none"
                />
                <div className="pointer-events-none absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center p-[16px] text-center text-[24px] uppercase leading-[85%] text-white">
                  {banner.subtitle && (
                    <span className="font-nimbus-sans-extd-d">{banner.subtitle}</span>
                  )}
                  {banner.title && (
                    <span className="font-nimbus-sans-extd-d font-bold">{banner.title}</span>
                  )}
                </div>
              </SiteLink>
            ))}
          </div>
        )}
      </>
    );
  };

  const findNavL2 = id =>
    mainNav?.linksCollection?.items
      ?.filter(filterByType('ComponentNav'))
      .find(({ sys }) => sys?.id === id);

  const previousSubmenu = previousSubmenuId ? findNavL2(previousSubmenuId) : null;
  const previousTransition = useTransition(previousSubmenu, {
    from: { opacity: 1 },
    enter: { opacity: 0 },
    leave: { opacity: 0 },
    config: springConfig.accordionContent,
    onRest: () => {
      if (previousSubmenuId) setPreviousSubMenuId(null);
    },
  });

  const currentSubmenu = submenuId && !previousSubmenuId ? findNavL2(submenuId) : null;
  const currentTransition = useTransition(currentSubmenu, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: springConfig.accordionContent,
  });

  const navL1 = mainNav?.linksCollection?.items?.map(props => {
    const { __typename, text, link, externalLink, title, sys, name } = props || {};
    if (__typename === 'ComponentLink') {
      return (
        <NavLinkLabelWrapper key={sys.id} item={props}>
          <SiteLink
            className="hover:underline"
            onMouseEnter={clearSubmenu}
            href={getLinkHref(link)}
            external={externalLink}
            id={`${gtmClickElementIds.header.header} ~ ${name}`}
          >
            {text}
          </SiteLink>
        </NavLinkLabelWrapper>
      );
    }
    if (__typename === 'ComponentNav') {
      const titleLink = link;

      const onHover = () => {
        if (submenuId !== sys.id) changeSubMenuId(sys.id);

        setSubmenuOpen(!(isScrolledDown && isScrollingDown));
      };

      if (titleLink) {
        return (
          <NavLinkLabelWrapper key={sys.id} item={props}>
            <SiteLink
              className={`cursor-pointer ${sys.id === submenuId && 'underline'}`}
              href={getLinkHref(titleLink.link)}
              external={titleLink.externalLink}
              onMouseEnter={onHover}
              id={`${gtmClickElementIds.header.header} ~ ${name}`}
            >
              {title}
            </SiteLink>
          </NavLinkLabelWrapper>
        );
      }

      return (
        <NavLinkLabelWrapper key={sys.id} item={props}>
          <button
            type="button"
            className={`cursor-pointer ${sys.id === submenuId && 'underline'}`}
            onMouseEnter={onHover}
            id={`${gtmClickElementIds.header.header} ~ ${name}`}
          >
            {title}
          </button>
        </NavLinkLabelWrapper>
      );
    }
    return null;
  });

  const dropdown = (
    <animated.div
      className="absolute left-0 top-[var(--height)] z-[1000] h-[360px] w-full overflow-y-hidden bg-white will-change-transform"
      style={slideStyle}
    >
      <div className="relative h-full w-full">
        {previousTransition(
          (styles, item) =>
            item && (
              <animated.div
                className="absolute flex w-full justify-between gap-[32px] p-[16px]"
                style={styles}
              >
                {renderNavL2(item)}
              </animated.div>
            )
        )}
        {currentTransition(
          (styles, item) =>
            item && (
              <animated.div
                className="absolute flex w-full justify-between gap-[32px] p-[16px]"
                style={styles}
              >
                {renderNavL2(item)}
              </animated.div>
            )
        )}
      </div>
    </animated.div>
  );

  const [visited, setVisited] = useState(false);

  useEffect(() => {
    if (isScrollingDown && !visited) setVisited(true);
  }, [isScrolledDown]);

  const shiftHeader = useMemo(() => {
    if (displayUspBanner) {
      return USP_BANNER_HEIGHT;
    }

    return '0px';
  }, [displayUspBanner]);

  return (
    <animated.header
      style={{
        '--height': HEADER_HEIGHT_DESKTOP,
        '--shiftHeader': shiftHeader,
        ...headerStyle,
      }}
      className={`fixed left-0 top-[var(--shiftHeader)] z-[9990] h-[var(--height)] w-full items-center justify-between px-[32px] transition-colors duration-500 ${
        isTransparent && !visited ? 'text-white' : 'bg-white text-blue'
      } ${isTransparent && !visited ? 'delay-[600ms]' : ''} hidden md:flex`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => {
        clearSubmenu();
        setHover(false);
      }}
    >
      <div className="flex items-center">
        <SiteLink href="/" className="h-[18px]" onMouseEnter={clearSubmenu}>
          <DenhamLogo className="h-[18px] w-[132px]" />
        </SiteLink>
        <nav className="ml-[38px] mt-[2px] space-x-[24px] text-[12px] leading-[14px]">{navL1}</nav>
      </div>
      <div
        onMouseEnter={() => setSubmenuOpen(false)}
        className="flex items-center space-x-[24px] text-[12px] leading-[14px]"
      >
        <SiteLink href="/search/" className="flex items-center">
          <SearchIcon className="h-6" />
        </SiteLink>
        <button
          type="button"
          className="whitespace-nowrap hover:underline"
          onClick={() => openStoreLocaleSelector({ store, locale })}
        >
          {storeLabels[store].toUpperCase()} / {localeLabels[locale].toUpperCase()}
        </button>
        <SiteLink
          id={`${gtmClickElementIds.header.account}`}
          href="/account/"
          className="hover:underline"
        >
          {accountMicrocopy}
        </SiteLink>
        <SiteLink id={`${gtmClickElementIds.header.wishlist}`} href="/wishlist/">
          <Heart className={`h-6 ${isWishlistPage ? 'fill-current' : ''}`}>
            {wishlistCount > 0 ? wishlistCount : ''}
          </Heart>
        </SiteLink>
        <button type="button" onClick={() => toggleCart()}>
          <CartIcon className="h-6">{cartCount > 0 ? cartCount : ''}</CartIcon>
        </button>
      </div>
      <div
        className={`absolute bottom-0 left-0 right-0 block h-[1px] bg-black transition-opacity duration-500 ${
          isTransparent ? 'opacity-0' : 'opacity-5'
        }
        ${isTransparent ? 'delay-[500ms]' : ''}`}
      />
      {dropdown}
    </animated.header>
  );
};

export default Desktop;
