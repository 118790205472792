import { useRouter } from 'next/router';

const Preview = ({ data: { message, exitUrl, exitText } }) => {
  const { asPath, locale: storeLocale } = useRouter();

  return (
    <div className="fixed bottom-2 left-2 z-[999] flex flex-col gap-2 rounded-md bg-blue bg-opacity-70 p-4 text-white">
      <p className="text-[15px]">{message}</p>
      <div className="flex gap-2">
        <a className="button bg-white text-blue" href={`${exitUrl}?dest=/${storeLocale}${asPath}`}>
          Exit
        </a>
        <button
          onClick={() => window.location.reload()}
          className="button bg-white text-blue"
          type="button"
        >
          Refresh
        </button>
      </div>
    </div>
  );
};

export default Preview;
