import NewsletterSignUp from 'components/newsletter-sign-up';
import { Icon, SiteLink } from 'components/shared';
import Button from 'components/shared/button';
import { useStoreContext } from 'contexts';
import { LINK_QUERY_FIELDS, NEWSLETTER_SIGN_UP_FIELDS } from 'lib/contentful/fragments';
import {
  getLinkHref,
  createNavGetterFromCollection,
  createMicroCopyGetterFromCollection,
} from 'lib/utils/helpers';
import { useState } from 'react';

export const getFooterQuery = ({ locale = 'en', preview = false }) => `
  {
    globalModuleCollection(
      locale: "${locale}"
      limit: 1
      where: { name_contains: "footer" }
      preview: ${preview ?? false}
    ) {
      items {
        name
        sys {
          id
        }
        resourcesCollection {
          items {
            ... on ComponentLink {
              name
              __typename
              ${LINK_QUERY_FIELDS}
            }
            ... on ComponentMedia {
              __typename
              name
              image {
                url
                width
                height
              }
            }
            ... on ComponentMicrocopy {
              __typename
              name
              text
            }
            ... on ComponentNav {
              __typename
              name
              linksCollection(limit: 30) {
                items {
                  ... on ComponentLink {
                    name
                    __typename
                    ${LINK_QUERY_FIELDS}
                  }
                }
              }
            }
            ... on ComponentNewsletterSignUp {
              ${NEWSLETTER_SIGN_UP_FIELDS}
            }
          }
        }
      }
    }
  }
`;

const iconsSrcMap = {
  facebook: '/icons/facebook-logo.svg',
  twitter: '/icons/twitter-logo.svg',
  instagram: '/icons/instagram-logo.svg',
};

const paymentIconsPerLocale = {
  eu: ['paypal', 'googlepay', 'ideal', 'visa', 'master'],
  us: ['paypal', 'googlepay', 'visa', 'master'],
  uk: ['paypal', 'googlepay', 'visa', 'master'],
};

const Footer = ({ data }) => {
  const [showPopup, setShowPopup] = useState(false);

  const { resourcesCollection, name } = data?.globalModuleCollection?.items[0];

  const getNavByName = createNavGetterFromCollection(resourcesCollection);
  const getMicrocopyByName = createMicroCopyGetterFromCollection(resourcesCollection);

  const mainNav = getNavByName('MainNav');
  const secondaryNav = getNavByName('SecondaryNav');

  const socialNav = getNavByName('SocialNav');

  const title = getMicrocopyByName('Newsletter title');
  const buttonText = getMicrocopyByName('Newsletter button text');

  const { store } = useStoreContext();

  const newsletterSignUp = resourcesCollection.items.find(
    ({ __typename }) => __typename === 'ComponentNewsletterSignUp'
  );

  const paymentIcons = (
    <div className="flex gap-1 text-white">
      {paymentIconsPerLocale[store]?.map((icon, i) => (
        <Icon
          // eslint-disable-next-line react/no-array-index-key
          key={`${icon}${i}`}
          className="h-7 w-12 text-white lg:h-[1.875rem] lg:w-[3.125rem]"
          type={icon}
        />
      ))}
    </div>
  );

  return (
    <>
      {newsletterSignUp && data?.resources && (
        <NewsletterSignUp
          name={name}
          resources={data.resources}
          show={showPopup}
          newsletterSignUp={newsletterSignUp}
          onClose={() => setShowPopup(false)}
        />
      )}
      <footer className="relative w-full antialiased">
        <div className="flex flex-col gap-16 bg-blue p-12 lg:flex-row-reverse lg:justify-between lg:px-[105px] lg:pt-[60px]">
          <div className="flex flex-col gap-12 text-white lg:gap-[5.12rem]">
            <div className="flex w-full flex-col items-center gap-5 text-center text-white lg:flex-row lg:items-center lg:justify-between lg:gap-[3rem] lg:text-left">
              {title && <p className="text-heading-7 uppercase  lg:max-w-[11rem]">{title}</p>}
              {buttonText && (
                <Button
                  onClick={() => setShowPopup(true)}
                  type="Button (White Outline)"
                  className="w-full lg:w-[17.625rem]"
                >
                  {buttonText}
                </Button>
              )}
            </div>
            <div className="flex flex-wrap gap-7 font-nimbus-sans-extd-d text-xs lg:flex-nowrap lg:justify-between lg:gap-16">
              <ul className="w-fit lg:grid lg:grid-cols-2 lg:gap-x-20">
                {mainNav?.map(link => (
                  <li className="w-fit" key={link.text}>
                    <SiteLink href={getLinkHref(link.link)} external={link.externalLink}>
                      {link.text}
                    </SiteLink>
                  </li>
                ))}
              </ul>
              <ul className="w-fit">
                {secondaryNav?.map(link => (
                  <li className="w-fit" key={link.text}>
                    <SiteLink
                      href={getLinkHref(link.link)}
                      external={link.externalLink}
                      query={link.query}
                    >
                      {link.text}
                    </SiteLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="relativ text-body-2 flex flex-col justify-between gap-[2.38rem]">
            <div className="flex flex-col gap-2">
              <SiteLink href="/">
                <img height={13} width={95} alt="Denham" src="/icons/denham-logo-footer.svg" />
              </SiteLink>
              <ul className="relative mt-2 flex items-center gap-2.5 lg:mt-6">
                {socialNav.map(
                  link =>
                    iconsSrcMap[link.text.toLowerCase()] && (
                      <li key={link.text}>
                        <SiteLink href={getLinkHref(link.link)} external={link.externalLink}>
                          <img
                            alt={link.text}
                            src={iconsSrcMap[link.text.toLowerCase()]}
                            width={12}
                            height={12}
                          />
                        </SiteLink>
                      </li>
                    )
                )}
              </ul>
            </div>
            {paymentIcons}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
