import React from 'react';
import { useStoreContext, useUI } from 'contexts';
import useSuggestStoreLocale from 'hooks/useSuggestStoreLocale';
import Cart from './cart';
import Footer from './footer';
import Header from './header';
import Preview from './preview';
import StoreLocaleSelector from './StoreLocaleSelector';
import UspBanner from './uspBanner';

const Layout = ({
  cart,
  header,
  footer,
  previewData,
  page,
  children,
  currency,
  uspBanner,
  storeLocaleSelector,
}) => {
  // eslint-disable-next-line no-underscore-dangle
  const pageType = page?.__typename;
  const isProductPage = pageType === 'PageProduct';

  const { locale } = useStoreContext();
  const { shiftContent, shiftContentDesktop } = useUI();

  useSuggestStoreLocale();
  // useNewsletterPopupTrigger();

  return (
    <>
      {cart && <Cart data={cart} page={page} />}
      {storeLocaleSelector && <StoreLocaleSelector data={storeLocaleSelector} />}
      {uspBanner && <UspBanner data={uspBanner} />}
      {header && (
        <Header
          data={header}
          transparency={page?.headerTransparent || false}
          pageType={pageType}
          locale={locale}
          currency={currency}
        />
      )}
      {previewData && <Preview data={previewData} />}
      <main
        style={{
          '--shiftContent': shiftContent,
          '--shiftContentDesktop': shiftContentDesktop,
        }}
        className={
          !isProductPage ? 'pt-[var(--shiftContent)] md:pt-[var(--shiftContentDesktop)]' : undefined
        }
      >
        {children}
      </main>

      {/* {!router.asPath.startsWith('/account') && !router.asPath.startsWith('/404') && (
        <NewsletterPopup newsletterPopup={newsletterPopup} currency={currency} />
      )} */}

      {footer && <Footer data={footer} />}
    </>
  );
};

export default Layout;
