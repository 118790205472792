/* 
  Name: Button Component
  Description: 
  - A button component which is used to render a button or a link with a button style.
  - Correlated with Component - Button entries in Contentful.
  - Used primarily in hero section, article section, and sub-campaign section.
*/

import { animated } from 'react-spring';
import { SiteLink } from 'components/shared';
import { getLinkHref } from 'lib/utils/helpers';
import classNames from 'classnames';

const Button = ({
  link: { link: buttonLink, externalLink, query, text: buttonText } = {},
  type,
  animation,
  id,
  className,
  children,
  onClick,
  disabled,
}) => {
  const linkClassNames = {
    'Text (White)':
      'button leading-[1.2] underline px-6 bg-transparent pointer-events-none capitalize',
    'Text (Blue)':
      'button !font-nimbus-sans-d underline !leading-[1.2] px-6 bg-transparent text-blue lg:group-hover/button:!opacity-40 group-active/button:!opacity-40 pointer-events-none capitalize',
    'Text (Blue Bold)':
      'button text-sm uppercase underline font-bold !leading-[0.9]  group-active/button:text-[12px] lg:group-hover/button:!opacity-40 group-active/button:!opacity-40 px-6 bg-transparent text-blue pointer-events-none',
    'Button (White)':
      'button w-full bg-white px-6 text-blue lg:px-12 pointer-events-none group-disabled/button:!bg-gray-300 group-disabled/button:!text-blue lg:group-hover/button:bg-opacity-50 group-active/button:bg-opacity-50',
    'Button (Blue)':
      'button w-full bg-blue px-6 text-white lg:px-12 pointer-events-none group-disabled/button:!bg-gray-300 group-disabled/button:!text-blue lg:group-hover/button:bg-opacity-50 group-active/button:bg-opacity-50',
    'Button (Outline)':
      'button w-full bg-transparent outline outline-1 outline-blue text-blue lg:px-12 pointer-events-none group-disabled/button:text-gray-300 group-disabled/button:outline-gray-300 lg:group-hover/button:border-opacity-50 group-active/button:border-opacity-50',
    'Button (White Outline)':
      'button w-full bg-transparent outline outline-1 outline-white text-white lg:px-12 pointer-events-none lg:group-hover/button:border-opacity-50 group-active/button:border-opacity-50 group-disabled/button:text-gray-300 group-disabled/button:outline-gray-300',
  };

  return (
    <SiteLink
      className={classNames(
        'group/button block p-[1px] lg:p-0 [&>div]:transition-all [&>div]:duration-300 [&>div]:ease-in-out',
        className
      )}
      href={getLinkHref(buttonLink)}
      external={externalLink}
      query={query}
      id={id}
      onClick={onClick}
      disabled={disabled}
    >
      <animated.div
        className={linkClassNames[type] || linkClassNames['Text (White)']}
        style={animation}
      >
        {children || buttonText}
      </animated.div>
    </SiteLink>
  );
};

export default Button;
