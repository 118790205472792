import { useEffect, useRef, useState } from 'react';
import renderRichtext from 'lib/render-richtext';
import { getResource, getResourceText } from 'lib/resources';
import { trackEvents, trackInWindow } from 'hooks/tracking/track';
import { useStoreContext } from 'contexts';
import Popup from './shared/popup';

const NewsletterSignUp = ({ name, resources, show, newsletterSignUp, onClose }) => {
  const inputRef = useRef();

  const { store, currency } = useStoreContext();

  const { klaviyoListIdEu, klaviyoListIdUk, klaviyoListIdUs } = newsletterSignUp;

  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(null);

  const t = (resource, fallback) => getResourceText(resources)(resource, fallback);
  const disclaimer = getResource(resources)('Disclaimer')?.richText;
  const errorText = t('Error text');

  const listId = {
    eu: klaviyoListIdEu || t('Klaviyo-listid-EUR'),
    uk: klaviyoListIdUk || t('Klaviyo-listid-GBP'),
    us: klaviyoListIdUs || t('Klaviyo-listid-USD'),
  }[store];

  const submit = async e => {
    e.preventDefault();

    const values = Object.fromEntries(new FormData(e.target).entries());

    setError(null);
    setLoading(true);

    const enabledFieldValues = Object.keys(values).reduce(
      (filtered, key) => ({ ...filtered, [key]: values[key] }),
      {}
    );

    const response = await fetch('/api/newsletter/subscribe', {
      method: 'POST',
      headers: { currency },
      body: JSON.stringify({
        ...enabledFieldValues,
        listId,
        $source: `[Website] ${name}`,
      }),
    });

    if (response.status === 201) {
      trackInWindow({
        event: trackEvents.newsletterSignUpLegacy,
      });
      trackInWindow({
        event: trackEvents.newsletterSignUp,
      });
      setCompleted(true);
    } else if (response.status === 400) {
      const json = await response.json();
      setError(json.message || errorText);
    } else {
      setError(errorText);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.focus();
    }
  }, [completed, show]);

  return (
    <Popup
      show={show}
      closeButtonClassName="absolute !top-5 !right-5"
      onClose={() => {
        setError(null);
        setCompleted(false);
        if (onClose) onClose();
      }}
      newCloseIcon
      className="text-body-2 w-full max-w-[32rem] px-10 py-16 text-center lg:p-16 lg:text-left"
    >
      {completed ? (
        <p className="text-heading-6 mb-3 lg:text-heading-4">
          {t('Title completed', 'Thank you for signing up!')}
        </p>
      ) : (
        <>
          {newsletterSignUp.title && (
            <p className="text-heading-6 mb-3 lg:text-heading-4">{newsletterSignUp.title}</p>
          )}
          {newsletterSignUp.description && (
            <div>{renderRichtext(newsletterSignUp.description)}</div>
          )}
          <form onSubmit={submit} className="mt-5 flex flex-col gap-4 text-left">
            <input
              ref={inputRef}
              className="input w-full"
              placeholder={t('Form email label', 'Email')}
              required
              type="email"
              name="email"
            />
            <fieldset>
              <legend className="mb-3">
                {t('Form gender label', 'Which is your preferred collection?')}
              </legend>
              <div className="flex gap-4">
                <label className="leading-0 flex items-center gap-2.5" htmlFor="gender-men">
                  <input
                    className="-mt-[0.15em]"
                    id="gender-men"
                    name="gender"
                    type="radio"
                    value="male"
                    defaultChecked
                  />
                  {t('Form gender option male label', 'Men')}
                </label>
                <label className="flex items-center gap-2.5" htmlFor="gender-women">
                  <input
                    id="gender-women"
                    className="-mt-[0.15em]"
                    name="gender"
                    type="radio"
                    value="female"
                  />
                  {t('Form gender option female label', 'Women')}
                </label>
                <label className="flex items-center gap-2.5" htmlFor="gender-all">
                  <input
                    id="gender-all"
                    className="-mt-[0.15em]"
                    name="gender"
                    type="radio"
                    value="all"
                  />
                  {t('Form gender option all label', 'All')}
                </label>
              </div>
            </fieldset>
            <fieldset>
              <button type="submit" className="button mt-1 w-full">
                {loading
                  ? t('Button loading text', 'Loading...')
                  : newsletterSignUp.buttonText || 'Subscribe'}
              </button>
              {error && <p className="mt-[5px] text-red">{error}</p>}
            </fieldset>
            {disclaimer && (
              <div className="text-body-4 mt-3 text-[#676767]">{renderRichtext(disclaimer)}</div>
            )}
          </form>
        </>
      )}
    </Popup>
  );
};

export default NewsletterSignUp;
