import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { SiteLink } from 'components/shared';
import { getLinkHref } from './utils/helpers';

const renderRichtext = ({ json, links }) =>
  documentToReactComponents(json, {
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [INLINES.ENTRY_HYPERLINK]: (
        {
          data: {
            target: {
              sys: { id },
            },
          },
        },
        children
      ) => {
        const link = (links?.entries?.hyperlink || []).find(l => l?.sys?.id === id);

        if (!link) return children;

        return (
          <SiteLink className="underline" href={getLinkHref(link)}>
            {children}
          </SiteLink>
        );
      },
    },
  });

export default renderRichtext;
