/**
 * All navigation items (mobile + desktop) are wrapped in this component, this way you can add specific styling to links
 *
 * Item is of type Component - Link or Component - Nav
 */

const NavLinkLabelWrapper = ({ item, children }) => {
  const color = item?.emphasisColor;

  if (!children || !color) return children;

  if (color.toLowerCase() === 'red') return <span className="text-red">{children}</span>;

  return children;
};

export default NavLinkLabelWrapper;
