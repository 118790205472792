import useScrollLock from 'hooks/utils/useScrollLock';
import springConfigs from 'lib/utils/springConfigs';
import { useTransition, animated } from 'react-spring';
import { useEffect } from 'react';
import { Close, Close2 } from './icons/ui';
import MobileTouchArea from './mobile-touch-area';

const Popup = ({
  show,
  children,
  closeButtonClassName = '',
  className,
  fullscreenMobile,
  onClose,
  newCloseIcon = false,
}) => {
  useScrollLock(Boolean(show));

  const fade = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: springConfigs.fastClamped,
  });

  const appear = useTransition(show, {}); // Only mounts/unmounts

  useEffect(() => {
    const handleEscape = e => {
      if (e.key === 'Escape' && onClose) onClose();
    };
    window.addEventListener('keydown', handleEscape);
    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  return (
    <>
      {fade(
        (style, item) =>
          item && (
            <animated.button
              type="button"
              aria-label="Close"
              onClick={onClose}
              className={`fixed left-0 top-0 z-[10000] h-full w-full bg-blue bg-opacity-30 ${
                onClose ? '' : 'cursor-default'
              } ${show ? '' : 'pointer-events-none'}`}
              style={style}
            />
          )
      )}
      {appear(
        (style, item) =>
          item && (
            <animated.div
              className={`pointer-events-none fixed z-[10010] flex items-center justify-center lg:inset-16 ${
                fullscreenMobile ? 'inset-0' : 'inset-[15px]'
              }`}
              style={style}
            >
              <div
                className={`pointer-events-auto absolute max-h-full overflow-y-auto bg-white ${
                  fullscreenMobile ? 'h-full w-full lg:h-[unset] lg:w-[unset]' : ''
                } ${className}`}
              >
                {children}
                {onClose && (
                  <button
                    onClick={onClose}
                    type="button"
                    className={`absolute right-[25px] top-[25px] lg:right-10 lg:top-10 ${
                      newCloseIcon ? 'h-7 w-7 ' : 'h-3.5 w-3.5'
                    } ${closeButtonClassName}`}
                  >
                    <MobileTouchArea />
                    {newCloseIcon ? (
                      <Close2 className="h-full w-full text-current" />
                    ) : (
                      <Close className="h-full w-full text-current" />
                    )}
                  </button>
                )}
              </div>
            </animated.div>
          )
      )}
    </>
  );
};

export default Popup;
