import { useUI } from 'contexts';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Icon } from 'components/shared';
import { filterByName } from 'lib/utils/helpers';
import { hasSuggestedSessionKey } from 'hooks/useSuggestStoreLocale';
import Popup from 'components/shared/popup';
import { Check } from 'components/shared/icons/ui';
import storeLocales, { storeLabels, localeLabels } from 'lib/storeLocales/storeLocales';
import Cookies from 'js-cookie';
import COOKIE_ENUMS from 'lib/utils/cookieEnums';

export const getStoreLocaleSelectorQuery = ({ locale = 'en' }) => `
  {
    globalModuleCollection(
      locale: "${locale}"
      limit: 1
      where: { name_contains: "Store Locale Selector" }
    ) {
      items {
        resourcesCollection {
          items {
            ... on ComponentMicrocopy {
              name
              text
            }
          }
        }
      }
    }
  }
`;

const pluckText = ({ text }) => text;
const createCopyGetterFromCollection = collection => name =>
  collection?.items.filter(filterByName(name)).map(pluckText)[0];

const StoreLocaleSelector = ({ data }) => {
  const { locale: currentStoreLocale } = useRouter();
  const { resourcesCollection } = data?.globalModuleCollection?.items[0];
  const { storeLocaleSlugs } = data;
  const { closeStoreLocaleSelector, displayStoreLocaleSelector } = useUI();
  const router = useRouter();
  const getCopyByName = createCopyGetterFromCollection(resourcesCollection);

  const suggestionText = getCopyByName('Store Locale Selector - Suggestion Text');
  const submitButtonText = getCopyByName('Store Locale Selector - Submit Button Text');

  const { store: defaultStore, locale: defaultLocale } = displayStoreLocaleSelector;

  const defaultStoreLocale =
    defaultStore && defaultLocale ? `${defaultStore}-${defaultLocale}` : storeLocales[0];

  const [storeLocale, setStoreLocale] = useState(defaultStoreLocale);

  useEffect(() => {
    setStoreLocale(defaultStoreLocale);
  }, [defaultStoreLocale]);

  const submit = e => {
    e.preventDefault();
    const currentPath = router.asPath;
    const path = storeLocaleSlugs ? storeLocaleSlugs[storeLocale] || '/' : currentPath;
    // router.push(path, path, { locale: storeLocale });

    // window.sessionStorage.setItem(hasSuggestedSessionKey, true);
    Cookies.set(COOKIE_ENUMS.SELECTED_STORE_LOCALE, storeLocale, { expires: 30 });

    if (currentStoreLocale !== storeLocale) {
      window.location = `/${[storeLocale, path].join('/')}`; // Reloads page, so SWR keys and elements on page refresh https://app.clickup.com/t/2t9h4ag
    } else {
      closeStoreLocaleSelector();
    }
  };

  return (
    <Popup key={displayStoreLocaleSelector} show={displayStoreLocaleSelector}>
      <section className="fixed left-0 top-0 z-[10010] flex w-full flex-col flex-wrap justify-between gap-[8px] bg-white p-4 font-nimbus-sans-extd-d text-[10px] uppercase text-blue md:flex-row md:items-center md:gap-x-[30px] md:px-[32px] md:py-[32px] md:text-[12px]">
        <p className="text-[12px] font-bold md:text-[20px]">{suggestionText}</p>
        <form
          onSubmit={submit}
          className="flex flex-wrap items-center gap-[8px] sm:gap-[16px] md:min-w-[500px]"
        >
          <div className="relative min-w-[300px] shrink-0 grow overflow-hidden">
            <div className="pointer-events-none absolute bottom-0 left-0 top-0 hidden items-center pl-[16px] sm:flex">
              <Check />
            </div>
            <div className="pointer-events-none absolute bottom-0 right-0 top-0 flex items-center pr-[12px] md:pr-[24px]">
              <Icon type="select" className="w-[12px] stroke-current" />
            </div>
            <select
              value={storeLocale}
              onChange={({ target: { value } }) => setStoreLocale(value)}
              className="input w-full appearance-none border-blue border-opacity-20 px-[8px] py-[12px] text-[10px] uppercase text-[inherit] sm:px-[12px] sm:pl-[32px] md:text-[12px]"
            >
              {storeLocales.map(l => (
                <option key={l} value={l} className="text-blue">
                  {l
                    .split('-')
                    .map((part, i) => (i === 0 ? storeLabels[part] : localeLabels[part]))
                    .join(' / ')}
                </option>
              ))}
            </select>
          </div>
          <button
            type="submit"
            className="button grow border border-blue px-12 text-[10px] md:text-[12px]"
          >
            {submitButtonText}
          </button>
        </form>
      </section>
    </Popup>
  );
};

export default StoreLocaleSelector;
