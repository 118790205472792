import { useState, useEffect, useMemo } from 'react';
import { useSpring, animated } from 'react-spring';
import { useUI, useCart } from 'hooks';
import { getLinkHref, filterByType, filterByName } from 'lib/utils/helpers';
import { SiteLink, MobileMenuButton, MobileMenuAccordion } from 'components/shared';
import {
  Search as SearchIcon,
  Globe as GlobeIcon,
  Cart as CartIcon,
  Heart,
} from 'components/shared/icons/ui';
import DenhamLogo from 'components/shared/icons/denham-logo';
import useDocumentScrollThrottled from 'hooks/utils/useDocumentScrollThrottled';
import useScrollLock from 'hooks/utils/useScrollLock';
import { useStoreContext } from 'contexts';
import springConfig from 'lib/utils/springConfigs';
import { useRouter } from 'next/router';
import useWishlist from 'hooks/swym/useWishlist';
import { HEADER_HEIGHT } from '../../../constants';
import Desktop from './desktop';
import { USP_BANNER_HEIGHT } from '../uspBanner';
import NavLinkLabelWrapper from './nav-link-label-wrapper';

const Header = ({ data, transparency: desktopTransparency = false, pageType = '' }) => {
  const {
    toggleCart,
    closeCart,
    displayCart,
    openStoreLocaleSelector,
    shiftContent,
    displayUspBanner,
  } = useUI();
  const { count: cartCount } = useCart();
  const { locale, store } = useStoreContext();
  const { pathname } = useRouter();
  const { count: wishlistCount } = useWishlist();

  const isProductPage = pageType === 'PageProduct';
  const isWishlistPage = pathname === '/wishlist';

  const mainNav = data.filter(filterByName('MainNav'))[0];
  const accountMicrocopy = data.filter(filterByName('Account'))[0]?.text;
  const mobileServicesNav = data.filter(filterByName('MobileServicesNav'))[0];

  const [submenuId, setSubmenuId] = useState(null);

  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);

  useDocumentScrollThrottled(({ currentScrollTop, isScrollingDown: documentScrollingDown }) => {
    const minimum = 75;
    setIsScrolledDown(currentScrollTop >= minimum);
    setIsScrollingDown(documentScrollingDown);
  });

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileSubmenuOpen, setMobileSubmenuOpen] = useState(false);
  const mobileSlideStyle = useSpring({
    transform: isMobileMenuOpen ? 'translateX(0%)' : 'translateX(100%)',
    config: springConfig.easeInButBetter,
  });
  const [mobileSubmenuSlideStyle, animateMobileSubmenuSlide] = useSpring(() => ({
    transform: isMobileSubmenuOpen ? 'translateX(0)' : 'translateX(0)',
    config: springConfig.easeInButBetter,
  }));

  useScrollLock(isProductPage || displayCart || isMobileMenuOpen);

  const handleCloseSubmenu = () => setMobileSubmenuOpen(false);

  useEffect(() => {
    animateMobileSubmenuSlide({
      transform: `translateX(${isMobileSubmenuOpen ? '-50%' : '0%'})`,
    });
  }, [animateMobileSubmenuSlide, isMobileSubmenuOpen]);

  const renderNavL3 = item => {
    const { __typename: type } = item || {};
    if (type === 'ComponentLink') {
      return (
        <NavLinkLabelWrapper key={item.sys.id} item={item}>
          <SiteLink
            className="relative mx-[30px] font-nimbus-sans-extd-d text-[14px] font-bold uppercase leading-[130%]"
            href={getLinkHref(item.link)}
            external={item.externalLink}
            onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          >
            {item.text}
          </SiteLink>
        </NavLinkLabelWrapper>
      );
    }

    if (type === 'ComponentNav') {
      const links = item?.linksCollection?.items
        ?.filter(Boolean)
        ?.filter(filterByType('ComponentLink'));
      const titleLink = item?.link;
      const showMobileLink = item?.showMobileLink || false;

      if (titleLink && showMobileLink && titleLink.text)
        links.push({ ...titleLink, highlighted: true });

      return (
        <MobileMenuAccordion
          className="relative mx-[30px]"
          title={<NavLinkLabelWrapper item={item}>{item.title}</NavLinkLabelWrapper>}
          list={links}
          onListItemClick={() => {
            setMobileMenuOpen(false);
            setMobileSubmenuOpen(false);
          }}
          key={item.sys.id}
          isExpandedByDefault={item.expandSubmenuMobile}
        />
      );
    }

    return null;
  };

  const renderNavL2 = nav => {
    const navL3Items = nav?.linksCollection?.items || [];
    const titleLink = nav?.link;
    const showMobileLink = nav?.showMobileLink || false;

    return (
      <div key={nav.sys.id} className="flex flex-col gap-[16px] first:mt-[38px]">
        {titleLink && showMobileLink && (
          <NavLinkLabelWrapper item={nav}>
            <SiteLink
              className="mx-[30px] mb-[8px] font-nimbus-sans-extd-d text-[14px] font-bold uppercase leading-[130%]"
              href={getLinkHref(titleLink.link)}
              external={titleLink.externalLink}
            >
              {titleLink.text}
            </SiteLink>
          </NavLinkLabelWrapper>
        )}
        {navL3Items.map(renderNavL3)}
      </div>
    );
  };

  const shiftHeader = useMemo(() => {
    if (displayUspBanner) {
      return USP_BANNER_HEIGHT;
    }

    return '0px';
  }, [displayUspBanner]);

  return (
    <>
      <Desktop
        transparency={desktopTransparency}
        isScrolledDown={isScrolledDown}
        isScrollingDown={isScrollingDown}
        data={data}
      />
      <header
        style={{
          '--height': HEADER_HEIGHT,
          '--shiftHeader': shiftHeader,
        }}
        className="fixed top-[var(--shiftHeader)] z-[9990] flex h-[var(--height)] w-full items-center justify-center bg-white md:hidden"
      >
        <div
          className={`absolute left-0 right-0 bottom-0 mb-[-1px] block h-[1px] bg-black transition-opacity duration-200 md:visible md:opacity-100 ${
            displayCart ? 'opacity-0' : 'opacity-5'
          }`}
        />
        <div className="ml-[16px] mr-auto flex gap-2">
          <MobileMenuButton
            isOpen={isMobileMenuOpen}
            onClick={() => {
              closeCart();
              setMobileMenuOpen(!isMobileMenuOpen);
              setMobileSubmenuOpen(false);
            }}
          />
          <SiteLink href="/search/" onClick={() => setMobileMenuOpen(false)}>
            <SearchIcon className="h-6" />
          </SiteLink>
        </div>
        <SiteLink href="/" className="absolute h-[18px]" onClick={() => setMobileMenuOpen(false)}>
          <DenhamLogo className="h-[18px] w-[132px]" />
        </SiteLink>
        <div className="flex gap-2 pr-4">
          <SiteLink href="/wishlist/" onClick={() => setMobileMenuOpen(false)}>
            <Heart className={`h-6 ${isWishlistPage ? 'fill-current' : ''}`}>
              {wishlistCount > 0 ? wishlistCount : ''}
            </Heart>
          </SiteLink>
          <button
            type="button"
            onClick={() => {
              setMobileMenuOpen(false);
              toggleCart();
            }}
          >
            <CartIcon className="h-6">{cartCount > 0 ? cartCount : ''}</CartIcon>
          </button>
        </div>
      </header>
      <animated.div
        className="fixed top-0 bottom-[max(-1px,env(safe-area-inset-bottom))] z-[999] flex w-full bg-white md:hidden"
        style={{ '--paddingTop': shiftContent, ...mobileSlideStyle }}
      >
        <div className="relative flex h-full w-full flex-col justify-between">
          <animated.div
            className="flex h-[calc(100%-50px)] w-[200%]"
            style={mobileSubmenuSlideStyle}
          >
            <nav className="mt-[var(--paddingTop)] flex w-full flex-col items-center justify-center font-nimbus-sans-extd-d text-[20px] font-bold uppercase leading-[180%]">
              {mainNav?.linksCollection?.items?.map(item => {
                const { __typename, text, link, externalLink, title, sys } = item;
                if (__typename === 'ComponentLink') {
                  return (
                    <NavLinkLabelWrapper key={sys.id} item={item}>
                      <SiteLink
                        href={getLinkHref(link)}
                        external={externalLink}
                        onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                      >
                        {text}
                      </SiteLink>
                    </NavLinkLabelWrapper>
                  );
                }
                if (__typename === 'ComponentNav') {
                  return (
                    <NavLinkLabelWrapper key={sys.id} item={item}>
                      <button
                        className="cursor-pointer font-bold uppercase"
                        onClick={() => {
                          setMobileSubmenuOpen(true);
                          if (submenuId !== sys.id) setSubmenuId(sys.id);
                        }}
                        type="button"
                      >
                        {title}
                      </button>
                    </NavLinkLabelWrapper>
                  );
                }
                return null;
              })}
            </nav>
            <div className="flex w-full flex-col">
              <div className="mt-[var(--paddingTop)] flex h-[50px] items-center justify-between border-b-[1px] border-[rgba(0,0,0,0.05)]">
                <button className="ml-[20px]" onClick={() => handleCloseSubmenu()} type="button">
                  <div className="h-[10px] w-[10px] rotate-[-135deg] border-t border-r border-blue" />
                </button>
                <h1 className="mr-[16px] font-nimbus-sans-extd-d text-[14px] font-bold uppercase leading-[130%]">
                  {
                    mainNav.linksCollection.items?.filter(({ sys }) => sys.id === submenuId)[0]
                      ?.title
                  }
                </h1>
              </div>
              <div className="h-[calc(100vh-150px)] overflow-y-scroll text-blue">
                {mainNav?.linksCollection?.items
                  .filter(({ sys }) => sys.id === submenuId)
                  .map(renderNavL2)}
              </div>
            </div>
          </animated.div>
          <div className="flex h-full w-full items-center justify-evenly border-t-[1px] border-[rgba(0,0,0,0.05)] text-[12px]">
            <button type="button" onClick={() => openStoreLocaleSelector({ store, locale })}>
              <GlobeIcon className="h-[15px] w-[15px]" />
            </button>
            <SiteLink href="/account/">{accountMicrocopy}</SiteLink>
            {mobileServicesNav.linksCollection.items.map(link => (
              <SiteLink
                key={link.name}
                href={getLinkHref(link.link)}
                external={link.externalLink}
                onClick={() => setMobileMenuOpen(false)}
              >
                {link.text}
              </SiteLink>
            ))}
          </div>
        </div>
      </animated.div>
    </>
  );
};

export default Header;
